import Box from '@mui/material/Box';
import '../App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
function Footer() {
  const isMobile = useMediaQuery('(min-width:600px)');
  return (
    <Box
      sx={{
        display: 'flex',
        height: '40px',
        bottom: 0,
        width: '100%',
        zIndex: '9',
        fontSize: '10px',
        background: '#595959',
        color: 'white',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed'
      }}
    >
      <Container
        sx={{
          maxWidth: isMobile ? '80% !important' : '100% !important'
        }}
      >
        <Box sx={{ fontSize: isMobile ? '20px' : '16px' }}>
          Arkadiusz Janeczko - UX Design · 2024
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
