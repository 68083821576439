import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGithub
} from '@fortawesome/free-brands-svg-icons';

const SocialMediaLinks = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px'
    },
    icon: {
      color: '#333',
      fontSize: '1.5rem',
      transition: 'color 0.3s'
    },
    iconHover: {
      color: '#0077b5' /* Example hover color */
    }
  };

  const [hoverIndex, setHoverIndex] = React.useState(null);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const socialMediaLinks = [
    { href: 'https://www.facebook.com/arkadiusz.janeczko/', icon: faFacebook },
    { href: 'https://x.com/arekjaneczko', icon: faTwitter },
    { href: 'https://www.instagram.com/arekjaneczko', icon: faInstagram },
    { href: 'https://www.linkedin.com/in/arek-janeczko/', icon: faLinkedin },
    { href: 'https://www.github.com', icon: faGithub }
  ];

  return (
    <div style={styles.container}>
      {socialMediaLinks.map((link, index) => (
        <a
          key={index}
          href={link.href}
          style={{
            ...styles.icon,
            ...(hoverIndex === index ? styles.iconHover : {})
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={link.icon} />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLinks;
