import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './routes/root';
import AboutMe from './sections/main-view';

import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import './index.css';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="about-me/" replace />
  },
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'about-me/',
        element: <AboutMe />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
