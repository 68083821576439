import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Root() {
  const isMobile = useMediaQuery('(min-width:600px)');
  return (
    <Box id="content">
      <Outlet />
    </Box>
  );
}
