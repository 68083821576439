import '../App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const items = [
  { text: 'About me', url: '/', icon: '../menu2_1.svg' },
  {
    text: 'Portfolio',
    url: 'https://www.figma.com/design/DGJFJzTLOmJzxxBFjyic3S/Portfolio-online?node-id=0-1&t=WU66zmzCZbVbBY7F-1',
    icon: '../menu2_2.svg'
  },
  {
    text: 'Blog',
    url: 'https://medium.com/@arekjaneczko',
    icon: '../menu2_3.svg'
  },
  { text: 'Formly', url: 'https://formly.pl', icon: '../formly-logo.svg' }
];

function MenuItems() {
  const isMobile = useMediaQuery('(min-width:1250px)');

  return items.map((item, index) => (
    <>
      <a
        key={index}
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          key={index}
          variant="contained"
          sx={{
            textDecoration: 'none',
            textTransform: 'lowercase',
            color: '#000',
            backgroundColor: 'white',
            padding: '10px 20px',
            margin: '10px 0',
            color: '#464646',
            borderRadius: '8px',
            width: '100%',
            textAlign: 'center',
            fontSize: '20px',
            display: 'flex',
            border: '2px solid black',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 'rgb(0, 0, 0) 8px 8px 0px 0px',
            position: 'relative',
            borderRadius: '100px',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#ddd',
              boxShadow: 'rgb(0, 0, 0) 8px 8px 0px 0px'
            }
          }}
        >
          <Grid container alignItems="center">
            <Grid
              item
              sx={{
                display: 'flex',
                left: '15px',
                top: '5pxx'
              }}
            >
              <img
                src={item.icon}
                alt="SVG Image"
                style={{
                  height: '55px',
                  width: '55px',
                  opacity: '1',
                  right: '50px'
                }}
              />
            </Grid>
            <Grid
              item
              xs
              sx={{
                transform: 'translateX(-20px)',
                fontWeight: 700
              }}
            >
              {item.text}
            </Grid>
          </Grid>
        </Button>
      </a>
    </>
  ));
}

export default MenuItems;
