import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const StyledForm = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  const SERVICE_ID = 'service_s55v3s6';
  const TEMPLATE_ID = 'template_o4lzdxu';
  const USER_ID = 'a_KZqVnxSAyaobDnD';

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading spinner

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      () => {
        setEmailSent(true);
        setEmailError('');
        setLoading(false); // Stop loading spinner
      },
      (error) => {
        setEmailError('Error sending email: ' + error.text);
        setLoading(false); // Stop loading spinner
      }
    );
  };

  return (
    <Box
      component="form"
      ref={form}
      onSubmit={sendEmail}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: '100%',
        width: '100%',
        margin: 'auto',
        mt: 5,
        borderRadius: 1
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Get in Touch 🤝
      </Typography>
      {emailSent && (
        <Alert severity="success" onClose={() => setEmailSent(false)}>
          Email sent successfully!
        </Alert>
      )}
      {emailError && (
        <Alert severity="error" onClose={() => setEmailError('')}>
          {emailError}
        </Alert>
      )}
      <TextField
        sx={{ background: 'white' }}
        label="What’s your awesome name?"
        variant="outlined"
        name="user_name"
        required
        fullWidth
      />
      <TextField
        sx={{ background: 'white' }}
        label="Drop your email here"
        variant="outlined"
        type="email"
        name="user_email"
        required
        fullWidth
      />
      <TextField
        sx={{ background: 'white' }}
        label="What’s on your mind? Spill it out!"
        variant="outlined"
        name="message"
        required
        multiline
        rows={4}
        fullWidth
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: 'black',
          '&:hover': {
            backgroundColor: '#0056b3'
          }
        }}
      >
        {loading ? (
          <CircularProgress size={24} sx={{ color: 'white' }} />
        ) : (
          '🚀 Shoot me a message! 🚀'
        )}
      </Button>
    </Box>
  );
};

export default StyledForm;
