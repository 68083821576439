import Box from '@mui/material/Box';
import MainHeader from './header.js';
import '../App.css';
import Container from '@mui/material/Container';
import SocialMediaLinks from './social-media-links.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from './footer.js';
import MenuItems from './menu-items.js';
import StyledForm from './styled-form.js';
import Divider from '@mui/material/Divider';

function MainView() {
  const isMobile = useMediaQuery('(min-width:1250px)');

  return (
    <>
      <Container
        fixed
        sx={{
          maxWidth: isMobile ? '40% !important' : '100% !important',
          paddingBottom: '150px',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'column',
            gap: 10
          }}
        >
          <Box
            sx={{
              textAlign: 'left',
              marginTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              width: isMobile ? '100%' : '100%'
            }}
          >
            <MainHeader />
            <img
              src="../designer-desk.svg"
              alt="SVG Image"
              style={{
                width: '500px',
                opacity: '1',
                position: 'fixed',
                bottom: '-200px',
                zIndex: -1,
                right: '-50px'
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 3
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  position: 'relative',
                  width: '100%',
                  flexDirection: isMobile ? 'column' : 'column',
                  fontWeight: '300',
                  fontSize: '1.5rem'
                }}
              >
                <Box sx={{ width: isMobile ? '100%' : '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      p: 3
                    }}
                  >
                    <MenuItems />
                    <br />
                    <SocialMediaLinks />
                    <br />
                    <Divider
                      sx={{
                        width: '100%',
                        marginBottom: '10px',
                        height: '10px',
                        color: 'black'
                      }}
                    />
                    <StyledForm />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default MainView;
