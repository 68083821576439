import Box from '@mui/material/Box';
import '../App.css';

import useMediaQuery from '@mui/material/useMediaQuery';
function MainHeader() {
  const isMobile = useMediaQuery('(min-width:950px)');
  return (
    <div className="App">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 200,
          width: '100%',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: isMobile ? '10px' : '0',
          gap: 0,
          mt: 2,
          mb: 3
        }}
      >
        <Box
          sx={{
            height: '150px',
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src="https://media.licdn.com/dms/image/C4D03AQGuWxLw1useqw/profile-displayphoto-shrink_100_100/0/1584017047524?e=1723680000&v=beta&t=j_2hwBgwazXVyCW2Q_fhPuBaQMY5_WcV1iAb59sH2WM"
            height="96px"
            width="96px"
            style={{
              borderRadius: '100%',
              position: 'relative',
              zIndex: 9,
              border: '3px solid white'
            }}
          />
        </Box>
        <Box
          sx={{
            width: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            fontWeight: '900',
            fontSize: '1.4rem'
          }}
        >
          Arkadiusz Janeczko
        </Box>
        <Box
          sx={{
            width: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            fontWeight: '500',
            fontSize: '1rem',
            marginTop: '5px'
          }}
        >
          Product Designer - Gdańsk, Poland
        </Box>
      </Box>
    </div>
  );
}

export default MainHeader;
